<template>
  <main id="content" role="main">

    <!-- Hero Section -->
    <div class="position-relative bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/general-bg-1.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative">
        <div class="w-md-80 w-xl-60 text-center mx-md-auto">
          <div class="mb-7">
            <h1 class="mb-4">Press Room</h1>
            <p class="lead mb-4">News and updates about beNovelty and API community.</p>
          </div>
          <a id="ga-press-reach-out-button" class="btn btn-primary btn-wide transition-3d-hover mr-1 mr-sm-4 mb-2" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm">
            Reach Out
          </a>
          <a id="ga-press-download-kit-button" href="/document/benovelty-press-kit.zip" class="btn btn-outline-primary transition-3d-hover mb-2" download>
            Download Press Kit
          </a>
        </div>
      </div>
    </div>
    <!-- End Hero Section -->

    <div class="container space-2">
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Press Coverage</span>
        <h2>Latest News</h2>
      </div>
      <a v-for="item in block_item_list" :key="item.title" class="row align-items-lg-center mb-4 transition-3d-hover" :href="item.url" target="_blank" data-gtm-att="ga-press-latest-news-card">
        <div class="col-lg-6">
          <img class="img-fluid rounded" :src="require('@/assets/img/press/' + item.image_url)">
        </div>
        <div class="col-lg-6">
          <div class="card-header border-0 pb-0">
            <p>{{ item.date }}</p>
          </div>
          <div class="card-body">
            <h3 class="mb-3">{{ item.title }}</h3>
            <p class="lead">{{ item.organization }}</p>
          </div>
          <div class="card-footer border-0 pt-0">
            <a :href="item.url" target="_blank" class="font-weight-bold">Read more <i class="fas fa-angle-right fa-sm ml-1" /></a>
          </div>
        </div>
      </a>
    </div>

    <div class="container">
      <hr>
    </div>

    <div class="container space-2 space-bottom-lg-3" data-aos="fade-up" data-aos-delay="250">
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <h2>More of beNovelty in the News</h2>
      </div>
      <div class="row">
        <div v-for="(item, index) in press_list" :key="item.title" class="news col-sm-6 col-lg-4 mb-4" :style="index <= 5 ? '' : 'display: none'">
          <PressCard :item="item" />
        </div>
      </div>
      <div class="col-12 text-center">
        <button id="loadMoreNews" class="btn btn-primary transition-3d-hover mt-3 mt-md-5" data-gtm-att="ga-press-show-more-button" @click="showAllPressCard()">Show More</button>
      </div>
    </div>

    <!-- Recent Announcement -->
    <div id="press-news" class="bg-img-hero-center" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 w-lg-50 text-center mx-md-auto">
          <h2>Recent Announcement</h2>
        </div>
        <div class="text-center" :style="[{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-19-square.svg') + ')'},{backgroundPosition:'center'},{backgroundRepeat:'no-repeat'}]">
          <p class="lead">Check out beNovelty Blog for our latest announcement and product updates.</p>
          <span class="d-block mt-5">
            <a id="ga-press-blog-button" class="btn btn-primary transition-3d-hover" href="https://blog.openapihub.com/" target="_blank">Read the Blog</a>
          </span>
        </div>
      </div>
    </div>
    <!-- End Recent Announcement -->

    <!-- ========== Popup Modals ========== -->
    <!-- Hubspot Form - beNovelty General Inquiry Modal -->
    <div id="benGeneralForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="benGeneralFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="benGeneralFormTitle" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <form ref="benGeneralForm" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hubspot Form - beNovelty General Inquiry Modal -->
    <!-- ========== End Popup Modals ========== -->

  </main>
</template>

<script>
const $ = require('jquery')
import PressCard from '@/components/press-card'
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../assets/js/hs.core.js'
import '../../assets/js/hs.slick-carousel.js'
import '../../assets/vendor/slick-carousel/slick/slick.js'
export default {
  name: 'Press',
  components: {
    PressCard
  },
  data() {
    return {
      block_item_list: [
        {
          url: 'https://www.bschool.cuhk.edu.hk/press-releases/hong-kongs-top-ranked-innovative-companies-announced-at-corporate-innovation-index-awards-presentation-and-forum-2022/',
          image_url: 'cuhk-20221123.jpg',
          organization: 'CUHK Business School',
          title: 'Hong Kong\’s Top-ranked Innovative Companies Announced at Corporate Innovation Index Awards Presentation and Forum 2022',
          date: 'November 23, 2022'
        },
        {
          url: 'https://hk.finance.yahoo.com/news/apidays-hong-kong-2022-open-021500445.html',
          image_url: 'yahoo-20220811.jpg',
          organization: 'Yahoo Finance',
          title: 'Apidays Hong Kong 2022: Open API, API-First Transformation & Platform Economy in Hong Kong',
          date: 'August 11, 2022'
        }
      ],
      press_list: [
        {
          url: 'https://www.etnet.com.hk/www/tc/news/mediaoutreach_news_detail.php?newsid=155865&page=1',
          image_url: 'press-bg-4.svg',
          organization: '經濟通 ET Net',
          title: 'Apidays香港站2022：深入探討開放API 及平台經濟生態',
          date: 'August 11, 2022'
        },
        {
          url: 'https://sg.finance.yahoo.com/news/apidays-hong-kong-2021-deep-071000247.html',
          image_url: 'press-bg-6.svg',
          organization: 'Yahoo Finance',
          title: 'Apidays Hong Kong 2021: Deep Diving the Open API, Open Banking & Data Ecosystem in Hong Kong',
          date: 'August 17, 2021'
        },
        {
          url: 'http://www.etnet.com.hk/www/tc/news/mediaoutreach_news_detail.php?newsid=90518&page=1',
          image_url: 'press-bg-2.svg',
          organization: '經濟通 ET Net',
          title: 'Apidays香港站2021：深入探討香港的開放 API、開放銀行和數據生態圈',
          date: 'August 17, 2021'
        },
        {
          url: 'https://inews.hket.com/article/2972551',
          image_url: 'press-bg-5.svg',
          organization: 'HKET',
          title: '【初創招聘】科技園初創實行「Hybrid Work」模式　聘AI及金融科技人才',
          date: 'June 02, 2021'
        },
        {
          url: 'https://www.hkstp.org/press-room/close-to-30-open-banking-solutions-co-created-by-hong-kong-and-overseas-start-ups-at-hkstp-x-hsbc-api-ecobooster/',
          image_url: 'press-bg-3.svg',
          organization: 'HKSTP',
          title: 'Close to 30 Open Banking Solutions Co-created by Hong Kong and Overseas Start-ups at HKSTP x HSBC API EcoBooster',
          date: 'January 05, 2021'
        },
        {
          url: 'https://www.hk01.com/%E7%A4%BE%E6%9C%83%E6%96%B0%E8%81%9E/559168/%E9%A6%99%E6%B8%AF%E7%A7%91%E5%AD%B8%E5%9C%92hk10x%E5%89%B5%E7%A7%91%E7%B2%BE%E7%A5%9E-%E5%82%B3%E6%89%BF%E7%9F%A5%E8%AD%98%E8%BF%8E%E6%8E%A5%E6%8C%91%E6%88%B0?utm_source=01appshare&utm_medium=referral',
          image_url: 'press-bg-6.svg',
          organization: 'HK01',
          title: '香港科學HK10X創科精神 傳承知識迎接挑戰',
          date: 'December 09, 2020'
        },
        {
          url: 'https://www.hk01.com/%E8%B2%A1%E7%B6%93%E5%BF%AB%E8%A8%8A/551245/%E9%A6%99%E6%B8%AF%E7%A7%91%E6%8A%80%E5%9C%92%E5%85%AC%E5%8F%B8%E6%8E%A8%E5%8B%95%E9%96%8B%E6%94%BE%E9%87%91%E8%9E%8D-%E9%A6%96%E8%BE%A6api%E6%9C%83%E8%AD%B0%E9%A6%99%E6%B8%AF%E7%AB%99%E5%8F%8A%E6%94%AF%E6%8F%B4%E8%AA%8D%E8%AD%89%E8%A8%88%E5%8A%83',
          image_url: 'press-bg-1.svg',
          organization: 'HK01',
          title: '香港科技園公司推動開放金融 首辦API會議香港站及支援認證計劃',
          date: 'November 23, 2020'
        },
        {
          url: 'https://std.stheadline.com/kol/article/1722/%E5%89%B5%E7%A7%91%E7%94%9F%E6%B4%BB-%E5%89%B5%E7%A7%91%E5%BB%A3%E5%A0%B4-%E9%87%91%E8%9E%8D%E7%A7%91%E6%8A%80%E7%82%99%E6%89%8B%E5%8F%AF%E7%86%B1-%E5%85%A9%E5%A4%A7%E5%9C%92%E5%8D%80%E5%A4%A7%E5%BC%B5%E6%97%97%E9%BC%93',
          image_url: 'press-bg-4.svg',
          organization: 'Sing Tao Daily',
          title: '【創科廣場】金融科技炙手可熱 兩大園區大張旗鼓',
          date: 'November 11, 2020'
        },
        {
          url: 'https://www2.hkej.com/instantnews/hongkong/article/2632454/OneDegree%E5%A4%A5beNovelty%E6%8E%A8%E7%B6%B2%E7%B5%A1%E5%AE%89%E5%85%A8%E4%BF%9D%E9%9A%AA',
          image_url: 'press-bg-5.svg',
          organization: 'HKEJ',
          title: 'OneDegree夥beNovelty推網絡安全保險',
          date: 'November 11, 2020'
        },
        {
          url: 'https://www.hkstp.org/press-room/hkstp-and-benovelty-spark-digital-economy-drive-with-apidays-live-hong-kong/',
          image_url: 'press-bg-2.svg',
          organization: 'HKSTP',
          title: 'HKSTP and beNovelty Spark Digital Economy Drive with Apidays LIVE HONG KONG',
          date: 'November 4, 2020'
        },
        {
          url: 'https://www.edigest.hk/%e8%81%b7%e5%a0%b4/benovelty-%e6%8e%a8%e5%87%ba%e4%b8%8d%e5%90%8c-api%e5%b9%b3%e5%8f%b0-%e4%bd%9c%e7%b4%85%e5%a8%98-%e4%bf%83%e9%80%b2%e6%9c%ac%e5%9c%b0-api-%e7%94%9f%e6%85%8b%e7%99%bc%e5%b1%95-198100/',
          image_url: 'press-bg-6.svg',
          organization: 'Edigest (經濟一週)',
          title: 'beNovelty推出不同API平台作紅娘 促進本地API生態發展',
          date: 'November 1, 2020'
        },
        {
          url: 'https://unwire.pro/2020/10/22/benovelty/executive-interview/',
          image_url: 'press-bg-1.svg',
          organization: 'Unwire.Pro',
          title: '本地初創建 API 交易平台　連繫供求雙方推生態圈',
          date: 'October 22, 2020'
        },
        {
          url: 'https://youtu.be/U75bE9Al46U',
          image_url: 'press-bg-3.svg',
          organization: 'Unwire.Pro',
          title: '【專訪】推動本地 API 生態圈 創立交易平台',
          date: 'October 22, 2020'
        },
        {
          url: 'http://startupbeat.hkej.com/?p=94017',
          image_url: 'press-bg-1.svg',
          organization: 'Hong Kong Economic Journal Company Limited (信報財經新聞)',
          title: 'OpenAPIHub助企業推新服務 搜羅API供對接 省研發成本',
          date: 'October 19, 2020'
        },
        {
          url: 'http://www.etnet.com.hk/www/tc/news/prnewswire_news_detail.php?newsid=3134052_XG34052_2',
          image_url: 'press-bg-3.svg',
          organization: '經濟通 ET Net',
          title: '本地科技初創beNovelty主辦全港首個國際大型API活動「Apidays」',
          date: 'September 23, 2020'
        },
        {
          url: 'https://youtu.be/zOdVbmbzrc8?t=143',
          image_url: 'press-bg-4.svg',
          organization: 'TVB Pearl',
          title: 'TVB Pearl Magazine',
          date: 'August 26, 2020'
        },
        {
          url: 'https://std.stheadline.com/kol/article/789/%E5%89%B5%E7%A7%91%E7%94%9F%E6%B4%BB-%E5%89%B5%E7%A7%91%E5%BB%A3%E5%A0%B4-%E6%BB%99%E8%B1%90%E5%A4%A5%E7%A7%91%E5%AD%B8%E5%9C%92%E6%8E%A8API%E8%A8%88%E7%95%AB-%E7%99%BC%E6%8E%98%E9%96%8B%E6%94%BE%E9%8A%80%E8%A1%8C%E6%96%B9%E6%A1%88',
          image_url: 'press-bg-6.svg',
          organization: 'Sing Tao Daily',
          title: '【創科廣場】滙豐夥科學園推API計畫 發掘開放銀行方案',
          date: 'July 14, 2020'
        },
        {
          url: 'https://www.hkstp.org/press-room/hkstp-and-hsbc-join-hands-to-drive-open-banking-innovation-in-hong-kong/',
          image_url: 'press-bg-2.svg',
          organization: 'HKSTP & HSBC',
          title: 'HKSTP and HSBC Join Hands to Drive Open Banking Innovation in Hong Kong',
          date: 'July 13, 2020'
        },
        {
          url: 'https://www.hk01.com/%E9%96%8B%E7%BD%90/461059/hello-%E6%9C%AA%E4%BE%86%E4%BD%A0%E5%A5%BD-%E7%A7%91%E5%AD%B8%E5%9C%92%E5%90%B8%E6%89%8D%E5%8A%A9%E5%89%B5%E7%A7%91-%E7%B6%B2%E4%B8%8A%E6%8B%9B%E8%81%98%E7%8D%B2%E4%BC%81%E6%A5%AD%E7%9B%9B%E8%AE%9A',
          image_url: 'press-bg-5.svg',
          organization: 'HK01',
          title: '【Hello，未來你好！】科學園吸才助創科　網上招聘獲企業盛讚',
          date: 'April 17, 2020'
        },
        {
          url: 'https://www.businesswire.com/news/home/20191104006147/en/HKSTP-Showcases-Hong-Kong-Innovation-on-World-Stage-of-Web-Summit-2019-in-Portugal',
          image_url: 'press-bg-3.svg',
          organization: 'Business Wire',
          title: 'HKSTP Showcases Hong Kong Innovation on World Stage of Web Summit 2019 in Portugal',
          date: 'November 5, 2019'
        },
        {
          url: 'https://www.youtube.com/watch?v=qJPIRJiJwOU',
          image_url: 'press-bg-1.svg',
          organization: 'The Fintech Power 50',
          title: 'Innovate Finance Interview with beNovelty | IFGS2019',
          date: 'May 10, 2019'
        }
      ]
    }
  },
  computed: {
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    this.loadScriptBenGeneralForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "13239e83-63b2-4469-8e47-aaf91bdc82d2"
        });
      `
      this.$refs.benGeneralForm.appendChild(secondScript)
    })
  },
  methods: {
    showAllPressCard() {
      $('.news:hidden').fadeIn('slow')
      $('#loadMoreNews').fadeOut('slow')
    },
    loadScriptBenGeneralForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.benGeneralForm.appendChild(script)
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Press Room | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/press' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Press Room | beNovelty' },
        { property: 'og:description', content: 'News and updates about beNovelty and the API community.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/press' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>

