<template>
  <a class="card bg-img-hero w-100 h-100 transition-3d-hover" :href="item.url" target="_blank" :style="{'background-image': 'url(' + require('@/assets/svg/components/'+item.image_url) + ')'}" data-gtm-att="ga-press-more-news-card">
    <div class="card-body">
      <span class="d-block text-white font-weight-bold text-cap mb-5">{{ item.organization }}</span>
      <h3 class="text-white">{{ item.title }}</h3>
    </div>
    <div class="card-footer border-0 bg-transparent pt-0">
      <div class="d-flex justify-content-between">
        <span class="text-white small font-weight-bold">{{ item.date }}</span>
        <i class="fas fa-chevron-right text-white" />
      </div>
    </div>
  </a>
</template>

<script>
import 'bootstrap'

export default {
  name: 'PressCard',
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          url: '',
          image_url: '',
          organization: '',
          title: '',
          date: ''
        }
      }
    }
  }
}
</script>
